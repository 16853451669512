@import "../../SiteShared.scss";

.dates {
  // background:red;
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
  height: 75px;
}

.dropdownWorkOrder {
  .menu {
    // Dropdown menu working with this
  }
}

// accounting css
.accounting-wrapper {
  @include mobile-tablet {
    padding: 0 15px;
    .accounting-wrapper {
      padding: 0;
    }
  }
  .top-head {
    margin-bottom: 40px;
    @include mobile-tablet {
      margin-bottom: 20px;
    }
    h4 {
      font-size: 20px;
      font-weight: 800 !important;
      color: #171a1d;
      margin-bottom: 0px;
      font-family: "Be Vietnam", sans-serif !important;
    }
    h6 {
      font-size: 13px;
      font-weight: normal;
      color: #5e6267;
    }
  }
  .table-rw {
    .no-data-wrapper {
      display: flex;
      justify-content: center;
      h3 {
        display: flex;
        justify-content: center;
      }
    }
    .card {
      box-shadow: unset;
      border: 0px;
      margin-bottom: 30px;
    }
    .banner-image-wrap {
      height: 120px !important;
      position: relative;
      & > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 3px !important;
      }
      .content {
        position: absolute;
        top: 0px;
        bottom: 0px;
        padding: 37px;
        width: 100%;
        z-index: 9;
        .header,
        .description {
          color: #ffffff;
          text-align: left;
        }
        .header {
          font-size: 18px;
          font-weight: 800;
          line-height: 25px;
        }
        .description {
          margin-top: 0px;
          font-size: 12px;
          font-weight: normal;
        }
        img {
          position: absolute;
          top: 3px;
          right: 3px;
          cursor: pointer;
        }
      }
      &:after {
        content: "";
        position: absolute;
        left: 0px;
        right: 0px;
        width: 100%;
        bottom: 0px;
        background-image: linear-gradient(
          to bottom,
          #171a1d -23%,
          rgba(23, 26, 29, 0)
        );
        height: 100%;
        visibility: visible;
        border-radius: 3px;
      }
    }
    .assets-wrapper {
      border-radius: 3px;
      box-shadow: 0 11px 14px -10px #e5eced;
      height: 120px !important;
      @include mobile-tablet {
        height: auto !important;
        padding-top: 10px;
      }
      .content {
        display: flex;
        padding: 0px;
        @include mobile-tablet {
          flex-direction: column;
        }
        .ui.grid {
          width: 50%;
          margin: 0px;
          overflow: hidden;
          @include mobile-tablet {
            width: 100%;
          }
        }
        .column {
          width: auto;
          display: flex;
          flex-direction: column;
          justify-content: center;
          font-size: 12px;
          font-weight: normal;
          color: #5e6267;
          .header {
            font-size: 15px;
            color: #171a1d;
          }
        }
      }
      .header {
        display: flex;
        align-items: center;
        font-size: 18px;
        font-weight: 800;
        color: #3b1c5a;
        text-transform: uppercase;
      }
      .left-wrap {
        background-image: url("/assets/img/income-inequality.svg");
        background-repeat: no-repeat;
        background-position: top 16px left 30px;
        background-size: 140px;
        @include mobile-tablet {
          background-image: none;
          .header {
            padding-left: 15px !important;
            font-size: 16px !important;
          }
        }
        .header {
          padding-left: 245px;
        }
      }
      .right-wrap {
        display: flex;
        align-items: center;
        justify-content: space-around;
        .line-seperator {
          padding: 0px;
          border: 1px solid rgba(25, 21, 21, 0.1);
          margin: 0px 20px;
          height: 30px;
        }
        @include mobile-tablet {
          justify-content: space-between;
          .line-seperator {
            margin: 0 50px;
          }
        }
      }
    }
    .running-wrapper {
      border-radius: 3px;
      box-shadow: 0 11px 14px -10px #e5eced;
      width: 100%;
      h4 {
        font-size: 17px;
        font-weight: 800;
        color: #3b1c5a;
      }
      .content {
        padding: 25px 20px;
      }
    }
    .graph-data-wrapper {
      .ui.card {
        box-shadow: 0 11px 14px -10px #e5eced !important;
      }
      .content {
        padding-left: 0px !important;
        padding-right: 0px !important;
      }
      .ui.column.stripe-data {
        border-radius: 3px;
        background-color: rgba(94, 98, 103, 0.05);
        height: 65px;
        padding: 15px 30px;
        margin: 25px 0px !important;
        .column {
          padding: 0px;
          display: flex;
          flex-direction: column;
          span {
            font-size: 13px;
            font-weight: 500;
            color: #171a1d;
            font-family: "Be Vietnam", sans-serif !important;
          }
          label {
            font-size: 11px;
            font-weight: normal;
            font-family: "Be Vietnam", sans-serif !important;
          }
        }
      }
    }
    .graph-data-wrapper-dashboard {
      .ui.card {
        padding: 20px !important;
        box-shadow: 0 11px 14px -10px #e5eced !important;
      }
      .content {
        padding-left: 0px !important;
        padding-right: 0px !important;
      }
      .ui.stripe-data.column {
        border-radius: 3px;
        background-color: rgba(94, 98, 103, 0.05);
        height: 65px;
        padding: 15px 30px;
        margin: 25px 0px !important;
        @include mobile-tablet {
          padding: 15px;
          height: auto;
        }
        .column {
          padding: 0px;
          // display: flex;
          // flex-direction: column;
          span {
            font-size: 13px;
            font-weight: 500;
            color: #171a1d;
            font-family: "Be Vietnam", sans-serif !important;
          }
          label {
            font-size: 11px;
            font-weight: normal;
            font-family: "Be Vietnam", sans-serif !important;
          }
        }
      }
    }
    .header {
      display: flex !important;
      justify-content: space-between !important;
      h4 {
        font-size: 17px;
        font-weight: 800 !important;
        color: #3b1c5a;
        font-family: "Be Vietnam", sans-serif !important;
      }
      .right-col {
        span {
          font-size: 12px;
          font-weight: normal;
          color: #5e6267;
          padding-right: 10px !important;
        }
        select {
          border: 1px solid #e3e3e3;
          height: 35px;
          border-radius: 3px;
          width: 130px;
          font-size: 12px;
          font-weight: normal;
          padding: 0px 10px;
          color: #5e6267;
          cursor: pointer;
        }
      }
    }
  }
}
.hoverableHeader:hover {
  background: #c4bbce !important;
  cursor: pointer !important;
}
.hoverableHeader {
  span {
    float: right;
    font-weight: bolder;
    font-size: 100%;
  }
}
.filterInputBill {
  input {
    height: 14px !important;
  }
}

.segmentLoading {
  // background:red !important;
  .progress {
    // background:red !important;
    width: 85%;
    top: 50%;
    left: 7%;
  }
  .header::before {
    background: #f9fafc !important;
  }
}

.billactiongroup {
  .billactionapprove {
    background: green !important;
    color: white !important;
  }
  .billactiondelete {
    background: #ff7f7f !important;
    color: white !important;
  }
  .billactionvoid {
    background: #ff7f7f !important;
    color: white !important;
  }
  .billactionapprove:hover {
    background: grey !important;
  }
  .billactiondelete:hover {
    background: grey !important;
  }
  .billactionvoid:hover {
    background: grey !important;
  }
}

.bills-times {
  // background:red;
  &-disabled {
    pointer-events: none;
  }
  input {
    width: 92% !important;
    height: inherit !important;
  }
}
.select-ledgers {
  .menu.visible.transition {
    height: 150px !important;
  }
}

.button-hover-universal:hover {
  i {
    font-weight: bold;
    color: #755dd5 !important;
  }
  span {
    font-weight: bold;
    color: #755dd5 !important;
  }
}

.button-hover-universal {
  background: #dfe1e3 !important;
  i {
    background: #dfe1e3 !important;
    color: black;
  }
  span {
    text-transform: uppercase;
  }
}

.accounting-table-styling {
  max-height: 600px !important;
  // height:500px !important;
  overflow: auto;
  width: 100% !important;
}

.noPrint-button {
  color: white !important;
  text-transform: uppercase !important;
}
.noPrint-button:hover {
  color: #ebe8ee !important;
}

.ledger-modal-scroll {
  // max-height:300px;
  // overflow-y:overlay;
  // overflow-x:hidden;
  // width:100%;
}

.journal-entry-modal {
  .rdt {
    input {
      height: 100% !important;
      width: 86% !important;
    }
  }
  .ui.dropdown:not(.button) > .default.text,
  .ui.default.dropdown:not(.button) > .text {
    color: black;
  }
}

.tenants-container {
  .ui.dropdown:not(.button) > .default.text,
  .ui.default.dropdown:not(.button) > .text {
    color: black;
  }
}

.secondTableToggle {
  .arrow:hover {
    cursor: pointer;
  }
}
td.selectable:hover {
  cursor: pointer;
}
.poldata {
  input::placeholder {
    color: black !important;
  }
  .default {
    color: black !important;
    opacity: 1 !important;
  }
}

.lead-table-list tbody tr td {
  overflow-wrap: break-word;
  .icon-container {
    display: flex;
    justify-content: center;
  }
}
.rdtOpen .rdtPicker {
  right: 0px !important;
}

// Balance Sheet style
.balance-sheet-layout {
  @include mobile-tablet {
    overflow-x: hidden;
    padding: 0 15px;
  }
  .balance-sheet-table {
    width: 100%;
    overflow-x: auto;
    // Custom Scrollbar
    &::-webkit-scrollbar {
      height: 7px;
    }
    &::-webkit-scrollbar-track {
      background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background: #e3e0e0;
      border-radius: 3px;
    }
    table {
      min-width: 700px;
    }
  }
  &.journal-report-layout {
    table {
      min-width: 1130px;
    }
  }
}

.dropdownWorkOrder {
  .ui.selection.dropdown .menu {
    background: red !important;
    // max-height: 21.37142857rem !important;
  }
}
.receiveContent {
  display: flex !important;
  .receiveRight {
    flex:1 !important;
    tbody{
      margin-top:5px;
      max-height:304.55px;
      overflow-y:scroll;
    }
  }
  .receive-times {
    .rdtPicker {
      margin-left: 0px!important;
    }
  }
}


.balance-sheet-simplified {
  
}