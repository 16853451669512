.top-flex {
  display: flex;
  justify-content: space-between;
  margin-bottom:5px;
  .page-title {
    h3{
      font-size: 20px;
      font-weight: 800 !important;
  
    }
  }
  .page-button {
    /* Styles for the right div */
  }
}

