@import "../../../styles/_variables";
@import "../../../SiteShared.scss";

$max-height-of-chat-room-container: 480px;
$white: #fff;
$black: #000;
$roomFilterTabHeight: 45px;

.chat-room-list {
  width: 100%;
  background-color: $white;
  // min-height: $max-height-of-chat-room-container;
  height: 80vh;
  margin-top: 50px;
  border-radius: 6px;

  .no-conversation {
    display: flex;
    flex: 1;
    // min-height: $max-height-of-chat-room-container;
    height: 80vh;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img {
      margin: 0 auto;
    }

    .mw-container {
      max-width: 380px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .title {
      margin-top: 25px;
      color: $primary-color;
      font-size: 18px;
      font-weight: 800;
      text-align: center;
    }
    .description {
      margin-top: 15px;
      line-height: 22px;
      font-size: 13px;
      color: $gray-dark;
      text-align: center;
      font-weight: 500;

      b {
        color: $black;
      }
    }
  }

  .chat-container {
    display: flex;
    flex-direction: row;
    height: calc(100% - #{$roomFilterTabHeight});
    position: relative;

    .toggle-room-list {
      display: none;
      width: 40px;
      height: 40px;
      flex-shrink: 0;
      position: absolute;
      right: -40px;
      top: -40px;

      svg {
        width: 40px;
        height: 40px;
      }
    }

    .room-list-container {
      display: flex;
      flex-direction: column;
      width: 270px;
      overflow-y: auto;
      overflow-x: hidden;
      height: calc(80vh - #{$roomFilterTabHeight});
      justify-content: space-between;
      border-right: 1px solid
        rgba(red($gray-dark), green($gray-dark), blue($gray-dark), 0.05);

      .border-wrapper {
        flex: 1;
        border-bottom: 2px solid
          rgba(red($gray-dark), green($gray-dark), blue($gray-dark), 0.05);
      }

      .to-messages-nav {
        margin: 50px 15px;

        .archive-title {
          font-weight: 800;
          font-size: 13px;
          color: #171a1d;
        }
        a {
          color: $gray-dark;
          text-decoration: underline;
        }
      }

      .archived-button {
        background-color: rgba(
          red($primary-color),
          green($primary-color),
          blue($primary-color),
          0.1
        );
        width: 210px;
        height: 35px;
        color: $primary-color;
        text-align: center;
        line-height: 35px;
        border-radius: 3px;
        margin: 20px;
        font-weight: 800;
      }

      .room-list-item {
        width: 100%;
        height: 96px;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 5px 15px;
        cursor: pointer;
        border-bottom: 1px solid
          rgba(red($gray-dark), green($gray-dark), blue($gray-dark), 0.05);

        &.active {
          background-color: rgba(59, 28, 90, 0.02);
        }

        .avatar {
          width: 36px;
          height: 36px;
          align-self: center;

          img {
            width: 100%;
            height: 100%;
            border-radius: 18px;
          }
        }

        .info-wrapper {
          margin-left: 15px;
          flex: 1;
          font-weight: 400;

          .room-title {
            font-size: 13px;
            color: #171a1d;
            margin-bottom: 5px;
          }
          .preview {
            font-size: 11px;
            font-family: inherit;
            color: $gray-dark;
            margin-bottom: 15px;
          }
        }

        .actions {
          position: relative;
          margin: 20px 0 0;
          color: $gray-dark;
          align-self: flex-start;
        }
      }
    }

    .chat-window {
      display: flex;
      flex: 1;
      flex-direction: column;
      height: calc(80vh - #{$roomFilterTabHeight});

      .chat-header {
        display: flex;
        flex-direction: row;
        height: 80px;
        border-radius: 4px;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        border-bottom: 2px solid
          rgba(red($gray-dark), green($gray-dark), blue($gray-dark), 0.05);

        .avatar {
          margin: 0 10px;

          img {
            width: 36px;
            height: 36px;
            border-radius: 18px;
          }
        }

        .room-info {
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: center;

          .room-title {
            font-weight: 400;
            font-size: 13px;
            color: #171a1d;
            line-height: 16px;
          }
          .description {
            color: $gray-dark;
            font-weight: 400;
            font-size: 11px;
            line-height: 13px;
          }
        }

        .chat-contact-label {
          margin-top: 5px;
        }

        .search {
          margin: 5px 20px;
          cursor: pointer;
        }

        .actions {
          margin: 5px 25px 0 0;
          cursor: pointer;
        }
      }

      .chat-logs {
        display: flex;
        flex: 1;
        flex-direction: column;
        padding: 30px;
        overflow-y: auto;

        .log-body {
          max-width: 80%;
          font-weight: 400;
          font-size: 13px;
          background-color: rgba(
            red($gray-dark),
            green($gray-dark),
            blue($gray-dark),
            0.05
          );
          border-radius: 5px;
          padding: 15px 20px;
          margin-bottom: 15px;
          display: flex;
          flex-direction: row;
          align-self: flex-start;
          justify-content: center;

          &[media-type="image/jpeg"] {
            flex-direction: column;

            .time-type-wrapper {
              height: auto;
              justify-content: flex-end;

              .icon-wrapper {
                display: none;
              }
            }
          }

          .body {
            align-self: center;
          }

          .time-type-wrapper {
            display: flex;
            flex-direction: row;
            margin-left: 30px;
            height: 40px;
            align-items: center;
            justify-content: center;

            .icon-wrapper {
              display: flex;
              background-color: rgba(0, 0, 0, 0.1);
              width: 40px;
              height: 30px;
              border-radius: 13px;
              align-items: center;
              justify-content: center;
            }

            .time {
              min-width: 75px;
              font-style: italic;
              font-weight: 400;
              font-size: 11px;
              color: $gray-dark;
              text-align: right;
            }
          }

          &[is-sender="Y"] {
            align-self: flex-end;
            background-color: $primary-color;
            color: #fff;

            .time {
              color: #fff;
            }

            .icon-wrapper {
              background-color: rgba(255, 255, 255, 0.1);
            }
          }
        }
      }
    }
  }
}

.chat-contact-label {
  font-size: 10px;
  border-radius: 13px;
  padding: 0 10px;
  height: 20px;
  display: inline-block;
  cursor: pointer;
  line-height: 20px;

  &[type="homeowner"],
  &[type="HOMEOVNER"] {
    color: $primary-color;
    background-color: rgba(
      red($primary-color),
      green($primary-color),
      blue($primary-color),
      0.1
    );
  }
  &[type="manager"],
  &[type="MANAGER"] {
    color: $primary-color;
    background-color: rgba(
      red($primary-color),
      green($primary-color),
      blue($primary-color),
      0.1
    );
  }
  &[type="service_professional"],
  &[type="SERVICE_PROFESSIONAL"],
  &[type="service professional"],
  &[type="SERVICE PROFESSIONAL"] {
    color: rgba(43, 124, 166, 1);
    background-color: rgba(43, 124, 166, 0.1);
  }
  &[type="renter"],
  &[type="RENTER"] {
    color: rgba(17, 174, 125, 1);
    background-color: rgba(17, 174, 125, 0.1);
  }
  &[type="lead"],
  &[type="LEAD"] {
    color: rgb(41, 3, 15);
    background-color: rgba(41, 3, 15, 0.1);
  }
}

@include for-phone {
  .toggle-room-list {
    display: block !important;
    font-size: 35px;
  }

  .room-list-container {
    flex: 1;

    .room-list-item {
      .actions {
        align-self: center;
        margin: 0 0 0 10px;
      }
    }
  }

  .chat-window {
    &.chat-rooms {
      display: none !important;
    }

    .chat-header {
      .back {
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .chat-logs {
      padding: 0 !important;

      .log-body {
        width: 90%;
        padding: 5px 10px !important;
        flex-direction: column !important;
        margin-left: 10px;
        margin-right: 10px;

        .time-type-wrapper {
          align-self: flex-end;
          align-items: center;
        }
      }
    }
  }
}
