@import "_fonts.scss";
@import "./quillpro/quillpro-mixins";

@import url("https://fonts.googleapis.com/css2?family=Be+Vietnam:wght@300;400;500;600;700;800&display=swap");

$primary-font: "Be Vietnam", sans-serif;

// Colors
$primary-color: #4c158a;
$secondary-color: #f2fcfb;
$black: #000000;
$white: #ffffff;
$orange: #fa861c;
$orange-fade: #fef9f2;
$theme-text-color: #021e40;
$gray-fade: #efefef;
$gray-dark: #4f5b60;

$color-leasera-complement-primary: #d7516f;
$color-leasera-complement-bright: #f0718f;
$color-leasera-complement-light: #e45676;
$color-leasera-complement-medium: #d75172;
$color-leasera-complement-heavy: #8a3447;
$color-leasera-complement-dark: #31131a;

// Chart Colors

$color1: #ff638588;
$color2: #4bc0c088;
$color3: #ffce5688;
$color4: #e7e9ed88;
$color5: #36a2eb88;
$color6: #a236f488;
$color7: #8bc34a88;
$color8: #ffeb3b88;
$color9: #ff893b88;
$color10: #e91e6388;
$color11: #1ec9e988;
$color12: #e91ec988;

$color-twitter-blue: #2daae2; //Twitter Color

// Font families
$light-font: "Avenir-light", sans-serif;
$regular-font: "Avenir-regular", sans-serif;
$bold-font: "Avenir-bold", sans-serif;
$black-font: "Avenir-black", sans-serif;
