.custom-checkbox {
  display: block;
  position: relative;
  padding: 1px 0 0 30px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 13px;
  font-weight: 500;
  color: #5e6267;
  font-family: "Be Vietnam", sans-serif !important;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  .view-btn{
    font-size: 11px;
    color: #3b1c5a !important;
    text-decoration: underline;
  }
}

/* Hide the browser's default checkbox */
.custom-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.custom-checkbox .checkmark {
  position: absolute;
  background: url("/assets/img/custom-inactive-checkbox.svg") no-repeat;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
}

/* When the checkbox is checked, add a blue background */
.custom-checkbox input:checked ~ .checkmark {
  background: url("/assets/img/custom-checkbox-selected.svg") no-repeat;
}

/* Show the checkmark when checked */
.custom-checkbox input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */

// custom radio//

.custom-radio {
  display: block;
  position: relative;
  padding: 5px 0 0 30px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 13px;
  font-weight: 500;
  color: #5e6267;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.custom-radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.custom-radio .checkmark {
  position: absolute;
  background: url("/assets/img/custom-radio.svg") no-repeat;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
}

/* When the radio button is checked, add a blue background */
.custom-radio input:checked ~ .checkmark {
  background: url("/assets/img/custom-radio-selected.svg") no-repeat;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.renew-lease-row{
  .custom-checkbox{
    min-width: 220px;
  }
  .ui.checkbox{
    min-width: 220px;
    margin-right: 1rem;
  }
}
.no-radio{
  padding: 0;
  .custom-control-label{
    &::after{
      display: none;
    }
    &::before{
      display: none;
    }
  }
}
