@import "../../styles/quillpro/quillpro-variables";
@import "../../styles/_variables";
@import "../../SiteShared.scss";

.Select-option.is-selected {
  display: none;
}
.semanticModal {
  top: unset !important;
  right: unset !important;
  bottom: unset !important;
  left: unset !important;
  // overflow-y:auto;
}
.form-submit-leads {
  .form-control {
    padding: 0px;
    border-radius: 5px;
    border: 0px;
  }
}


/* error classes*/
.error-text {
  font-size: 13px;
  color: #ff3030;
  height: 20px;
}

.lead-set {
  cursor: not-allowed;
  input {
    user-select: none;
    pointer-events: none;
  }
}

.card {
  background-color: $accent-color-light;
  &-content {
    padding: 0px !important;
  }
  &-header {
    background-color: $dashboard-header-background-color;
    font-weight: 500;
    color: $dashboard-header-font-color !important;
  }
  .card-icon {
    float: right;
    font-size: 1em;
    line-height: 1em;
  }
}
//   &-content {
//     padding: 0px;
// .header.card-header {
// background-color: $dashboard-header-background-color;
// font-weight: 500;
// color: $dashboard-header-font-color;
// }
// }
// }

.ui.form {
  .fields {
    .wide.field.pr-15 {
      padding-left: 15px;
    }
  }
}

.form-control:disabled,
.form-control[readonly],
.form-control[readonly]:focus {
  background-color: #fff !important;
}
