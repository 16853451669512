.modal-ui {
  max-width: 500px !important;
  overflow: visible;
}
.journal-modal {
  max-width: 100vw !important;
}

.location-dropdown {
  background-color: #4c158a;
  color: #e8eef8;
  border: none;
  border-radius: 0.28571429rem;
  font-size: 1em;
  text-align: left;
  height: 40px;
  align-items: center;
  display: inline-flex !important;
  padding-left: 0.9em;
  padding-right: 0.9em;
  width: 140px;

  .text {
    color: black !important;
    margin-right: 10px;
  }

  input {
    top: 5px !important;
  }
}

.view-maintance-image {
  .pg-viewer-wrapper {
    .photo-viewer-container {
      height: 450px !important;
      width: auto !important;
    }
  }
}

.view-maintance-image {
  .pg-viewer-wrapper img {
    width: auto !important;
    height: auto !important;
    max-width: 832px;
    max-height: 100%;
    object-fit: cover;
  }
}

.charge-code-modal.custom-modal{
  max-width: 700px !important;
  width: 100% !important;
  padding: 30px 30px !important;
  .content{
    .form-group{
      margin-bottom: 10px !important;
    }
    .has-float-label{
      background-color: transparent;
      box-shadow: none;
      height: auto;
      .form-control{
        background-color: #fff !important;
        border-radius: 3px;
        box-shadow: 0 11px 14px -10px #e5eced !important;
        border: 0 !important;
        padding: 15px 20px 20px !important;
        height: 75px;
      }
      select.form-control{
        // & + label{
        //   top: 25px !important;
        //   font-size: 15px;
        // }
      }
      textarea.form-control{
        padding-top: 28px !important;
        height: auto;
      }
      .form-control:placeholder-shown:not(:focus) + *{
        top: 25px;
      }
      label{
        top: 7px;
      }
    }
  }
  .edit-charge-code{
    display: flex;
    flex-wrap: wrap;
    margin: 20px 0;
    .code-col{
      margin-right: 80px;
      .code-label{
        font-size: 13px;
        color: #5e6267;
      }
      .code-value{
        font-size: 13px;
        font-weight: 500;
        color: #171a1d;
      }
    }
  }
}
