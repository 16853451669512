@import url("https://fonts.googleapis.com/css2?family=Be+Vietnam:ital,wght@0,400;0,500;0,600;0,700;0,800;1,400&display=swap");
@import "float-label";
@import "custom-radio";
@import "upload-image";
@import "custom-dropdown";
@import "progress-bar";

.property-container {
  font-family: "Be Vietnam", sans-serif !important;
  /* Smoothing */
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
  line-height: normal;
  background: #fafafa !important;
  height: 100%;

  .header-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .left-container {
    .head {
      text-align: left;
      font-size: 20px;
      font-weight: 800;
      color: #171a1d;
    }

    p {
      font-size: 13px;
      color: #5e6267;
    }
  }
  .next-btn {
    width: 150px;
    height: 45px;
    padding: 0;
    border-radius: 3px;
    background-image: linear-gradient(103deg, #3b1c5a, #374db1 162%);
    font-size: 13px;
    font-weight: 800;
    text-align: center;
    color: #ffffff;
    text-transform: uppercase;
    margin: 0;
    line-height: 30px;
  }
  .right-container {
    display: flex;

    ul {
      list-style-type: none;
      display: flex;
      margin: 0 10px 0 0;
      width: 80px;
      height: 45px;
      padding: 5px 5px 5px 5px;
      border-radius: 3px;
      background-color: rgba(#000000, 0.05);

      li {
        a {
          width: 35px;
          height: 35px;
          display: flex;
          align-items: center;
          justify-content: center;

          &.active {
            background: #fff;
          }
        }
      }
    }
  }
  .custom-dropdown {
    border-radius: 3px;
    -webkit-backdrop-filter: blur(50px);
    backdrop-filter: blur(50px);
    // background-color: rgba(#171a1d, 0.65);
    color: #fff;
    // 
    // top: 10px;
    // right: 10px;
    padding: 0;
    // display: flex;
    align-items: center;
    // z-index: 10;
    .dropdown-list {
      top: -140px;
      right:50px;
      position: absolute;
      z-index: 99999 !important
    }
    .more-btn {
      width: 30px;
      height: 30px;
      line-height: 25px;
      text-align: center;
      border: 1px solid #e7e7e7;
    }
  }
  .card {
    z-index: 9 !important;

    width: calc(33.33% - 1.5em);
    height: 298px;
    padding: 0;
    margin-left: 0.75em;
    margin-right: 0.75em;
    margin-bottom: 20px;
    border-radius: 3px;
    box-shadow: 0 11px 14px -10px #e5eced;
    background-color: #ffffff;
    text-align: left;
    // overflow: hidden;
    position: relative;
    .img-box {
      width: 100%;
      height: 150px;
      overflow: hidden;
      position: relative;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      &.default-box {
        background-color: #e7e7e7;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 35px;
          height: 35px;
        }
      }
    }
    &.disabled-card {
      opacity: 0.7;
    }
  }
  .card-info {
    margin-top: 10px;
    padding: 15px 25px 15px 25px;
  }
  .card-titile {
    font-size: 14px;
    font-weight: 600;
    color: #171a1d;
  }
  .address {
    font-size: 13px;
    color: #5e6267;
    margin-top: 10px;
  }
  .card-aminites {
    span {
      font-size: 13px;
      font-weight: 500;
      color: #4c158a;
    }
    .dot {
      width: 4px;
      height: 4px;
      border-radius: 50%;
      display: inline-block;
      margin: 2px 10px;
      background-color: rgba(#6b66ff, 0.3);
    }
  }
  .black-strip {
    border-radius: 3px;
    -webkit-backdrop-filter: blur(50px);
    backdrop-filter: blur(50px);
    background-color: rgba(#171a1d, 0.65);
    border: 1px solid #e7e7e7;
    color: #fff;
    position: absolute;
    top: 10px;
    left: 10px;
    display: flex;
    align-items: center;
    padding: 10px;
    span {
      font-size: 12px;
      font-weight: 500;
    }
    .bar {
      color: #fff;
      margin: 0 10px;
      height: 10px;
      display: inline-block;
      border-right: 1px solid #fff;
    }
    &.right-side {
      right: 10px;
      left: auto;
      padding: 5px;
      cursor: pointer;
    }
  }

  .card-inner-container {
    display: flex;
    flex-wrap: wrap;
    margin: 30px -12px 0;
    &.list-view {
      margin: 30px 0;
      .card-row {
        width: 100%;
        margin: 0;
        text-align: left;
        display: flex;
        box-shadow: 0 11px 14px -10px #e5eced;
        background-color: #ffffff;
        padding: 20px;
        align-items: center;
        border-radius: 3px;
        margin-bottom: 15px;
        .img-box {
          width: 40px;
          height: 40px;
          border-radius: 6px;
          overflow: hidden;
        }
        .black-strip {
          position: relative;
          display: inline-flex;
          padding: 7px 10px;
          background: rgba(#000000, 0.05);
          color: #5e6267;
        }
        .custom-dropdown {
          display: inline-flex;
          position: relative;
          top: auto;
          left: auto;
          background: rgba(#000000, 0.05);
          .more-btn {
            background: transparent;
          }
        }
        .block {
          flex-shrink: 0;
          &:first-child {
            width: 40px;
          }
          &:nth-child(2) {
            width: 40%;
            padding-left: 10px;
            .address {
              margin: 0;
            }
          }
          &:nth-child(3) {
            width: 30%;
          }
          &:nth-child(4) {
            width: calc(30% - 40px);
            display: flex;
            align-items: center;
            justify-content: flex-end;
            .black-strip {
              left: 0;
              top: 0;
              .bar {
                color: #bdbdbd;
                border-right: 1px solid #bdbdbd;
              }
            }
            .custom-dropdown {
              right: 0;
              margin-left: 10px;
            }
          }
        }
      }
    }
  }
}
.grey-btn {
  background: rgba(#3b1c5a, 0.1) !important;
  color: #3b1c5a !important;
  width: 100%;
  margin-top: 20px;
  font-size: 11px;
  line-height: normal;
  font-weight: 800;
  text-transform: uppercase;
}

* {
  box-sizing: border-box;
}

.add-property-container {
  font-family: "Be Vietnam", sans-serif !important;
  /* Smoothing */
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
  line-height: normal;
  background: #fafafa !important;
  height: 100%;
  width: 100%;
  letter-spacing: normal;
  box-sizing: border-box;
  // display: none  !important;
  #customAmenity {
    margin-top: 20px;
  }
  .link-btn {
    margin-top: 20px !important;
    span.text-priamry {
      padding-left: 5px !important;
    }
  }
  .header {
    font-size: 20px;
    font-weight: 800;
    line-height: 1;
    color: #171a1d;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .back-btn {
      width: 45px;
      height: 45px;
      padding: 0;
      border-radius: 3px;
      background-color: rgba(#000000, 0.05);
      display: inline-block;
      line-height: 38px;
      text-align: center;
      margin-right: 15px;
    }
    .btn-section {
      display: flex;
      align-items: center;
      .custom-dropdown {
        margin-left: 10px;
      }
    }
  }

  button.leasera {
    background-color: #4c158a;
    color: #fff;
  }

  .m-t-3 {
    margin-top: 30px;
  }

  .menu-container {
    width: 100%;
    // height: 60px;
    margin-top: 30px;
    background-color: #ffffff;
    border-radius: 3px;
    box-shadow: 0 11px 14px -10px #e5eced;
    position: relative;
    z-index: 4;
    .list-container {
      margin: 0;
      padding: 0;
      list-style-type: none;
      align-items: center;
      display: flex;
      li {
        width: 20%;
        text-align: left;
        padding: 19px 0 19px 15px;
        display: flex;
        font-size: 11px;
        color: #1a1f27;
        cursor: pointer;
        span {
          width: 20px;
          height: 20px;
          background-color: rgba(#5e6267, 0.15);
          font-size: 10px;
          font-weight: 800;
          text-align: center;
          color: #171a1d;
          display: inline-block;
          border-radius: 50%;
          line-height: 18px;
          margin-right: 10px;
        }

        &.active {
          border-bottom: 1px solid #4c158a;
          font-size: 11px;
          font-weight: 500;
          color: #4c158a;
          span {
            background-color: #4c158a;
            color: #fff;
          }
        }
      }
    }
    .loader-block {
      font-size: 13px;
      font-weight: 500;
      color: #5e6267;
      text-align: left;
      padding: 20px 15px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      z-index: 4;
      position: relative;
      top: -65px;
      span,
      .spinner {
        color: #4c158a;
      }
    }
  }

  .white-block {
    padding: 15px 20px 25px;
    border-radius: 3px;
    box-shadow: 0 11px 14px -10px #e5eced;
    background-color: #ffffff;
    text-align: left;

    .head {
      font-size: 13px;
      font-weight: 500;
      color: #5e6267;
    }
  }

  .radio-container {
    display: flex;
    margin-top: 15px;
    flex-wrap: wrap;

    .custom-checkbox,
    .custom-radio {
      margin-right: 30px;

      a {
        color: #5e6267;
        &:hover {
          color: #4c158a;
        }
      }
    }
  }

  .amenites-box {
    display: flex;
    flex-wrap: wrap;
    .head {
      width: 100%;
    }
    .radio-container {
      width: 25%;
    }
  }

  .btn-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .grey-block {
    text-align: left;
    padding: 30px;
    background: rgba(#000000, 0.05);

    .head {
      font-size: 14px;
      font-weight: 800;
      color: #171a1d;
    }

    p {
      font-size: 12px;
      color: #5e6267;
    }

    .form-row {
      margin-bottom: 15px;
      align-items: flex-start;
      &:last-child {
        margin-bottom: 0;
      }
    }

    .input-section {
      width: calc(100% - 60px);
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      .select-box {
        width: 49%;
        margin-bottom: 10px;

        .ui.dropdown {
          min-height: 50px;
          height: auto;
          border: 0;
          width: 100% !important;
          margin: 0;
          display: flex;
          flex-wrap: wrap;
          .icon {
            top: 12px;
          }
        }

        .input-box {
          width: 100%;
          position: relative;

          input {
            height: 50px;
            padding: 0 15px;
            margin: 0;
            border: 0;
            width: 100%;
            box-shadow: none;
            box-sizing: border-box;
          }

          .hint {
            position: absolute;
            top: 15px;
            right: 10px;
            font-size: 13px;
            font-weight: 500;
            text-align: right;
            color: #5e6267;
          }
        }
      }
    }

    .delete-btn {
      width: 50px;
      height: 50px;
      margin: 0 0 0 10px;
      padding: 0;
      border-radius: 3px;
      background-color: rgba(#000000, 0.05);
      line-height: 45px;
      text-align: center;
    }
  }

  .ui.upward.dropdown > .menu {
    .item {
      font-size: 13px;
      font-weight: 400;
      border: 0;

      span {
        font-size: 13px;
        font-weight: 400;
        color: #5e6267;
      }
    }
  }

  .cancel-btn {
    width: 200px;
    height: 50px;
    border-radius: 3px !important;
    background-color: #6d6d6d !important;
    font-size: 13px !important;
    font-weight: 800 !important;
    text-align: center !important;
    color: #ffffff !important;
    text-transform: uppercase !important;
    padding: 0;
    margin: 0;
    line-height: normal;
  }

  .save-btn {
    width: 200px;
    height: 50px;
    padding: 0;
    border-radius: 3px;
    background-color: rgba(#4c158a, 0.1);
    font-size: 13px;
    font-weight: 800;
    text-align: center;
    color: #4c158a !important;
    margin-right: 10px;
    text-transform: uppercase;
  }

  .next-btn {
    width: 200px;
    height: 50px;
    padding: 0;
    border-radius: 3px;
    background-image: linear-gradient(103deg, #3b1c5a, #374db1 162%);
    font-size: 13px;
    font-weight: 800;
    text-align: center;
    color: #ffffff;
    text-transform: uppercase;
  }

  .csv-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;
    padding: 15px 30px;

    .head {
      font-size: 15px;
      font-weight: 800;
      color: #171a1d;
    }

    .next-btn {
      margin: 0;
      width: 120px;
      height: 40px;
      line-height: 20px;
    }
  }

  .unit-row {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 20px;

    &.header-row {
      margin: 30px 0 10px 0;
    }

    .unit-inner {
      width: calc(100% - 45px);
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      .block {
        width: 9%;
        text-align: left;
        padding-right: 10px;
        position: relative;
        flex-wrap: wrap;
        &:nth-child(1) {
          width: 5%;
        }
        &:nth-child(10),
        &:nth-child(5) {
          width: 14%;
        }
        input {
          width: 100%;
          height: 50px;
          margin: 0;
          padding: 5px;
          border-radius: 3px;
          box-shadow: 0 11px 14px -10px #e5eced;
          background-color: #ffffff;
          box-sizing: border-box;
        }
        .upload-btn {
          width: 65px;
          height: 50px;
          margin: 0 10px 0 0;
          padding: 0;
          border-radius: 3px;
          background-color: rgba(#000000, 0.03);
          color: #000;
          border: 0;
          cursor: pointer;
          font-size: 12px;
        }
        .number-btn {
          display: flex;
          position: absolute;
          right: 30px;
          top: 14px;

          .number-icon {
            width: 20px;
            height: 20px;
            margin: 0 3px;
            padding: 0;
            text-align: center;
            border-radius: 3px;
            color: #5e6267;
            font-size: 14px;
            line-height: 18px;
            background-color: rgba(#000000, 0.05);
          }
        }
        .has-float-label {
          height: 50px;
        }
        .select-float-label .ui.fluid.dropdown {
          height: 50px;
          padding: 15px;
          margin: 0px;
          .text {
            font-size: 12px;
            white-space: nowrap;
            display: block;
            padding-right: 10px;
          }
        }
        .select-float-label .ui.fluid.dropdown .dropdown.icon {
          top: 14px !important;
        }
        .unit-select-btn {
          width: 80px;
          height: 50px;
          padding: 5px 10px;
          border-radius: 3px;
          box-shadow: 0 11px 14px -10px #e5eced;
          background-color: #ffffff;
          font-size: 12px;
          cursor: pointer;
          font-weight: 500;
          text-align: center;
          color: #171a1d;
          text-decoration: underline;
          border: 0;
        }
        .floor-plan-container {
          position: relative;
          .ui.selection.active.dropdown .menu {
            width: 250px !important;
            min-width: 250px !important;
            padding: 0 0;
            border-radius: 3px !important;
            box-shadow: 0 11px 14px -10px #e5eced !important;
            background-color: #ffffff !important;
            top: 50px;
            .btn-box {
              padding: 20px;
              .add-btn {
                border: 0;
                width: 100%;
                height: 35px;
                padding: 0 15px;
                border-radius: 3px;
                font-size: 12px;
                font-weight: 800;
                text-align: center;
                color: #3b1c5a;
                text-transform: uppercase;
                background-color: rgba(#3b1c5a, 0.1);
              }
            }
            .item {
              font-size: 13px;
              color: #5e6267;
            }
          }
          .text {
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }
      }
    }

    .text {
      font-size: 11px;
      font-weight: 500;
      color: #5e6267;
      text-transform: uppercase;
    }
  }

  .custom-dropdown {
    position: relative;

    .more-btn {
      width: 45px;
      height: 50px;
      border-radius: 3px;
      background-color: rgba(#000000, 0.03);
      display: block;
      line-height: 43px;
      text-align: center;
    }

    .dropdown-list {
      display: none;
      width: 275px;
      padding: 10px 25px 10px 25px;
      border-radius: 3px;
      box-shadow: 0 11px 14px -10px #e5eced;
      background-color: #ffffff;
      position: absolute;
      right: 0;
      text-align: left;
      z-index: 10 !important;

      .list-item {
        a {
          font-size: 13px;
          font-weight: 500;
          color: #5e6267;
          display: flex;
          align-items: center;
          padding: 7px 15px;

          img {
            margin-right: 10px;
          }
        }

        &.red-text {
          a {
            color: #ef2c2c;
          }
        }
      }
    }
  }

  .unit-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;
    position: relative;
    .cancel-btn {
      height: 40px;
      background: rgba(#000000, 0.03);
      font-size: 11px;
      font-weight: 600;
      color: #5e6267 !important;
      width: 49%;
    }
  }

  .create-account-form {
    .text {
      color:black;
    }
  }
}
.custom-btn-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  min-width: 200px;
  .save-btn {
    background-image: linear-gradient(266deg, #7127c4, #4c158a) !important;
    width: 80px;
    height: 40px;
    margin: 0px;
    font-size: 14px;
    span {
      color: #fff !important;
    }
    &:hover {
      background-image: linear-gradient(266deg, #7127c4, #4c158a);
    }
  }
  .cancel-btn {
    color: #fff !important;
    text-align: left;
    padding: 0px;
    margin: 0px;
    background-color: #6d6d6d !important;
    height: 40px;
    width: 110px;
    text-align: center;
  }
}
.review-container {
  margin-top: 30px;

  .zillow-switch-container {
    display: flex;
    flex: 1;
    flex-direction: row;
    height: 30px;

    .zillow-switch-label {
      margin-right: 10px;
    }

    .zillow-switch-content {
      width: 60px;
      height: 60px;
      display: inline-block;

      .ui.toggle.checkbox input:checked ~ .box:before,
      .ui.toggle.checkbox input:checked ~ label:before {
        background-color: #4c158a !important;
      }
    }
  }

  .img-box {
    width: 100%;
    height: 250px;
    border-radius: 6px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .review {
    &-mb10 {
      margin-bottom: 10px;
    }
    &-hours {
      background-color: rgba(0, 0, 0, 0.05);
      padding: 16px 0 6px 30px;
      border-radius: 3px;
    }
  }

  .propery-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 40px 0 40px 0;
    .propery-name {
      font-size: 20px;
      font-weight: bold;
      color: #4c158a;
    }

    .address {
      font-size: 14px;
      color: #696d72;
    }

    .property-info {
      font-size: 13px;
      font-weight: 500;
      color: #696d72;

      .dot {
        width: 4px;
        height: 4px;
        border-radius: 50%;
        opacity: 0.3;
        display: inline-block;
        margin: 2px 4px;
        background-color: rgba(#5e6267, 0.3);
      }
    }

    .big-text {
      font-size: 22px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #181b20;

      .bar {
        line-height: normal;
        display: inline-block;
        font-size: 11px;
        font-weight: 300;
        margin: 0 10px;
        color: rgba(#171a1d, 0.3);
        position: relative;
        top: -4px;
      }
    }
  }

  .grey-row {
    padding: 26px 30px;
    border-radius: 3px;
    background-color: rgba(#000000, 0.05);
    text-align: left;
    display: flex;
    margin-bottom: 40px;
    flex-wrap: wrap;

    .block {
      width: 16.66%;
      flex-shrink: 0;
    }

    .head {
      font-size: 12px;
      color: #5e6267;
    }

    .text {
      font-size: 14px;
      font-weight: 500;
      color: #171a1d;
    }
  }

  h3 {
    font-size: 14px;
    font-weight: normal;
    color: #5e6267;
    text-align: left;
    margin-bottom: 0;
  }

  p {
    font-size: 14px;
    line-height: 25px;
    color: #171a1d;
    text-align: left;
  }

  .pets-section {
    padding: 20px 30px 30px;
    border-radius: 3px;
    background-color: rgba(#000000, 0.05);
    text-align: left;
    margin-bottom: 40px;

    .head {
      font-size: 14px;
      font-weight: 800;
      color: #171a1d;
    }

    .head-row {
      display: flex;
      align-items: center;
      width: 100%;
      padding: 10px 10px;

      div {
        font-size: 11px;
        color: #5e6267;
        width: 20%;
        flex-shrink: 0;

        &:last-child {
          width: 10%;
        }
      }
    }

    .data-row {
      background: #fff;
      margin-bottom: 10px;
      border-radius: 3px;
      display: flex;
      align-items: center;
      width: 100%;
      padding: 13px 15px;

      div {
        width: 20%;
        font-size: 14px;
        line-height: 1.79;
        color: #171a1d;
        flex-shrink: 0;

        &:last-child {
          width: 10%;
        }
      }
    }
  }

  .w-30 {
    width: 33.33%;
  }

  .m-t-4 {
    margin-top: 40px;
  }

  .small-card-container {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
    .small-switch {
      position: absolute;
      bottom: 5px;
      right: 10px;
    }

    .card-row {
      width: 100%;
      margin: 0;
      text-align: left;
      display: flex;
      box-shadow: 0 11px 14px -10px #e5eced;
      background-color: #ffffff;
      padding: 20px;
      align-items: center;
      border-radius: 3px;
      margin-bottom: 15px;

      .img-box {
        width: 40px;
        height: 40px;
        border-radius: 6px;
        overflow: hidden;
      }
      .black-strip {
        position: relative;
        display: inline-flex;
        padding: 7px 10px;
        background: rgba(#000000, 0.05);
        color: #5e6267;
      }
      .card-aminites {
        span {
          font-size: 13px;
          font-weight: 500;
          color: #4c158a;
        }
        .dot {
          width: 4px;
          height: 4px;
          border-radius: 50%;
          display: inline-block;
          margin: 2px 10px;
          background-color: rgba(#6b66ff, 0.3);
        }
      }
      .block {
        flex-shrink: 0;
        &:first-child {
          width: 40px;
        }
        &:nth-child(2) {
          width: 40%;
          padding-left: 10px;
          .address {
            margin: 0;
          }
        }
        &:nth-child(3) {
          width: 30%;
        }
        &:nth-child(4) {
          width: calc(30% - 40px);
          display: flex;
          align-items: center;
          justify-content: flex-end;
          .black-strip {
            left: 0;
            top: 0;
            .bar {
              color: #bdbdbd;
              border-right: 1px solid #bdbdbd;
            }
          }
        }
      }
    }
  }

  .small-card-block {
    padding: 20px 20px 55px 20px;
    border-radius: 3px;
    box-shadow: 0 11px 14px -10px #e5eced;
    background-color: #ffffff;
    margin: 10px;
    display: flex;
    position: relative;

    @media screen and (min-width: 476px) {
      .small-card-block {
        width: 31%;
      }
    }

    .top-chip {
      background: rgba(#3b1c5a, 0.1);
      width: 58px;
      height: 20px;
      padding: 0 5px;
      border-radius: 10px;
      font-size: 10px;
      font-weight: bold;
      text-align: center;
      color: #3b1c5a;
      text-transform: uppercase;
      position: absolute;
      top: 10px;
      right: 10px;
      line-height: 19px;
    }
    .img-container {
      width: 50px;
      height: 50px;
      border-radius: 3px;
      overflow: hidden;
      flex-shrink: 0;
      margin-right: 15px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .normal-text {
      font-size: 12px;
      color: #5e6267;
      margin-bottom: 0;
      line-height: normal;
    }

    .bold-text {
      font-size: 12px;
      font-weight: 600;
      margin-bottom: 0;
      color: #171a1d;
      line-height: normal;
    }

    .small-text {
      font-size: 12px;
      font-weight: 500;
      color: #5e6267;
      display: flex;
      align-items: center;
      margin-top: 5px;
      .dot {
        width: 4px;
        height: 4px;
        margin: 0 10px;
        border-radius: 50%;
        background-color: rgba(#5e6267, 0.3);
      }
    }
  }
  .unit-dropdown-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .right-container {
      display: flex;
      margin: 0 10px;
      ul {
        list-style-type: none;
        display: flex;
        margin: 0 10px 0 0;
        width: 80px;
        height: 45px;
        padding: 5px 5px 5px 5px;
        border-radius: 3px;
        background-color: rgba(#000000, 0.05);

        li {
          a {
            width: 35px;
            height: 35px;
            display: flex;
            align-items: center;
            justify-content: center;

            &.active {
              background: #fff;
            }
          }
        }
      }
    }
    .filter-section {
      display: flex;
      align-items: center;
      width: 300px;
      .normal-text {
        font-size: 12px;
        color: #171a1d;
        display: inline-block;
        margin-right: 10px;
      }
      .dropdown {
        background: rgba(#000000, 0.05);
        border: 0;
        height: 35px;
        margin: 0;
        font-size: 12px;
        .icon {
          background: transparent !important;
          padding: 5px 0 !important;
        }
      }
    }
  }
}

.custom-modal {
  width: 500px !important;
  padding: 50px 50px !important;
  margin: 0 !important;
  border-radius: 6px !important;
  background-color: #fafafa !important;
  font-family: "Be Vietnam", sans-serif !important;
  &.amenitie-modal {
    width: 590px !important;
    .amenites-box {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 30px;
      .head {
        width: 100%;
        display: none;
      }
      .radio-container {
        width: 33.33%;
      }
    }
    .img-box {
      width: 100%;
      height: 180px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .aminites-header-block {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 20px 0;
      .unit-name {
        font-size: 20px;
        font-weight: bold;
        color: #3b1c5a;
        margin: 0;
      }
      .bold-text {
        margin: 0;
        font-size: 18px;
        font-weight: bold;
        color: #181b20;
      }
      .small-text {
        display: flex;
        align-items: center;
        span {
          font-size: 13px;
          font-weight: 500;
          color: #5e6267;
        }
        .dot {
          width: 4px;
          height: 4px;
          border-radius: 50%;
          display: inline-block;
          margin: 2px 10px;
          position: relative;
          top: 2px;
          background-color: rgba(#5e6267, 0.3);
        }
      }
    }
    h5 {
      font-size: 14px;
      color: #5e6267;
    }
    .ameniti-text {
      font-size: 14px;
      line-height: 25px;
      letter-spacing: normal;
      color: #171a1d;
    }
    .row-item {
      .head {
        font-size: 14px;
        color: #5e6267;
      }
      .text {
        font-size: 14px;
        letter-spacing: normal;
        color: #171a1d;
        margin-top: 10px;
      }
    }
    .floor-item-row {
      display: flex;
      align-items: center;
      margin-top: 20px;
      justify-content: space-between;
    }
  }
  .header {
    background: transparent !important;
    padding: 0 !important;
    border: 0 !important;
    .header {
      font-size: 22px;
      font-weight: 800;
      color: #171a1d;
      margin: 0 0 5px 0;
      padding: 0;
      font-family: "Be Vietnam", sans-serif !important;
    }
    p {
      font-size: 15px;
      color: #5e6267;
    }
  }
  .content {
    background: transparent !important;
    padding: 0 !important;
    margin-top: 40px;
    border: 0 !important;
    .form-group {
      margin-bottom: 25px !important;
    }
  }
  .close {
    top: 15px !important;
    right: 15px !important;
    &:before {
      color: #171a1d;
    }
  }
  &.add-property-modal {
    max-width: 500px;
    width: 100% !important;
    border-radius: 3px !important;
    background-color: #fafafa !important;
    .header {
      text-align: center;
      .ui.header {
        margin-bottom: 10px;
        color: #3b1c5a;
        font-size: 25px;
      }
      p {
        font-size: 13px;
        margin-bottom: 0px;
        line-height: 25px;
      }
    }
    .content {
      .white-block {
        height: 70px;
        border-radius: 3px;
        box-shadow: 0 11px 14px -10px #e5eced;
        background-color: #ffffff;
        display: flex;
        align-items: center;
        margin-top: 0px;
        margin-bottom: 20px;
        font-size: 14px;
        font-weight: 600 !important;
        padding: 15px 110px !important;
        cursor: pointer;
        background-repeat: no-repeat;
        background-position: center left 30px;
        &.apartment-rw {
          background-image: url(/assets/img/apartment.svg);
        }
        &.condo-rw {
          background-image: url(/assets/img/condo.svg);
          background-size: 60px;
        }
        &.house-rw {
          background-image: url(/assets/img/house.svg);
        }
        &.office-rw {
          background-image: url(../../assets/images/undraw_office.svg);
          background-size: 66px;
        }
        &.multi-rw {
          background-image: url(../../assets/images/undraw_multi.svg);
          background-size: 60px;
        }
      }
      button {
        margin-top: 10px;
      }
    }
  }
  &.unit-modal {
    max-width: 568px;
    width: 100% !important;
    .header {
      text-align: left;
    }
  }
  &.renewal-summary-modal {
    .content {
      margin-top: 20px;
      .prop-address {
        font-size: 20px;
        font-weight: bold;
        color: #3b1c5a;
      }
      .prop-subaddress {
        font-size: 14px;
        color: #5e6267;
        margin-top: 5px;
      }
      .renewal-summary-col {
        margin-top: 20px;
        .row {
          margin-bottom: 20px;
        }
        .summary-label {
          color: #5e6267;
        }
        .summary-value {
          line-height: 1.79;
          color: #171a1d;
          margin-top: 5px;
        }
      }
    }
  }
}
.modal-save-button {
  // width: 100%;
  // height: 35px;
  // margin: 0;
  // padding: 0 26px 0 27px;
  // border-radius: 3px;
  // 
  // font-size: 13px;
  // font-weight: 800;
  // color: #ffffff;
  // text-transform: uppercase;
  // border: 0;
  // line-height: normal;
}
.unit-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  background: rgba(#171a1d, 0.1);
  &.active {
    display: block;
  }
}
.add-unit-box {
  width: 310px;
  height: 242px;
  padding: 30px;
  border-radius: 6px;
  background-color: #fafafa;
  position: absolute;
  right: 0;
  bottom: 42px;
  display: none;
  font-family: "Be Vietnam", sans-serif !important;
  &.active {
    display: block;
  }
  h2 {
    font-size: 16px;
    font-weight: 800 !important;
    text-align: left;
    color: #171a1d;
  }
  .form-group {
    margin-bottom: 20px;
  }
}
.after-upload {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  .img-box {
    width: 25px;
    height: 25px;
    background: #f2f2f2;
    display: none;
    &:first-child {
      display: block;
    }
    .inner-box {
      width: 23px;
      height: 23px;
      border-radius: 3px;
      overflow: hidden;
      position: relative;
      .overlay-num-box {
        border-radius: 2px;
        background-color: rgba(#171a1d, 0.7);
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        font-size: 10px;
        font-weight: 800;
        text-align: center;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    &:nth-child(2) {
      left: 23px;
    }
    &:nth-child(3) {
      left: 35px;
    }
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.add-floor-modal {
  width: 700px !important;

  .content {
    .form-group {
      margin-bottom: 0 !important;
    }
  }
  .preview-box {
    margin-bottom: 0;
  }
  .modal-save-button {
    width: 49%;
    height: 45px !important;
    max-height:45px !important;
    background-image: linear-gradient(96deg, #3b1c5a, #374db1 163%) !important;
  }
  .modal-close-button {
    width: 49%;
    height: 45px;
  }
  .btn-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
}
.loader-overlay {
  z-index: 3;
  position: relative;
  &:after {
    content: "";
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(#000000, 0.1);
    top: 0;
    left: 0;
  }
}
.link-btn {
  border: 0 !important;
  box-shadow: none !important;
  background: transparent !important;
  color: #5e6267 !important;
  padding: 0 !important;
  &:hover,
  &:focus {
    background: transparent !important;
  }
}

.container-lease {
  position: relative;
  width: 100%;
  max-width: 200px;
}

.container-lease img {
  width: 100%;
  height: auto;
}

.container-lease .btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  background-color: #555;
  color: white;
  font-size: 16px;
  padding: 1px 24px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  text-align: center;
}

.container-lease .btn:hover {
  background-color: black;
}

.policy-wrap {
  h3 {
    font-size: 13px;
    font-weight: 500;
    margin-bottom: 0px;
    color: #696d72 !important;
  }
  .policy-col {
    button {
      margin-left: auto;
    }
    display: flex;
    align-items: center;
    margin-top: 5px;
    p {
      margin-bottom: 0px;
      a {
        font-size: 14px;
        color: #171a1d;
      }
    }
    i {
      line-height: 18px;
      margin-left: 10px;
      &.fa-file {
        margin-left: 0px;
        margin-right: 8px;
      }
    }
  }
}

// Review details link
.preview-link {
  font-size: 14px !important;
  color: #171a1d !important;
}

.fileupload-rw {
  padding: 15px 0px 0px !important;
  & > label {
    padding: 15px 20px;
    margin-bottom: 0px;
  }

  .file-upload {
    position: relative;
    height: 60px;
    overflow: hidden;
    input {
      position: absolute;
      left: 0;
      top: 0;
      opacity: 0;
      padding: 0px !important;
      margin-bottom: 0px;
      bottom: 0px;
      cursor: pointer;
    }
    label {
      color: #5e6267;
      background-color: unset;
      cursor: pointer;
      margin-bottom: 0px;
      display: flex;
      font-size: 12px;
      font-weight: normal;
      background: url(/assets/img/icons-upload-file.svg), rgba(0, 0, 0, 0.05);
      border-radius: 0px 0px 3px 3px;
      padding: 18px 50px;
      width: 100%;
      height: 60px;
      background-repeat: no-repeat;
      align-items: center;
      background-position: center left 20px;
    }
  }
}
.benefits-modal-docu {
  .modal-dialog {
    max-width: 99%;
    margin: 0;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 90vh;
    z-index: 1;
    display: flex;
    .modal-body {
      padding: 0px !important;
    }
  }
}

// bluemoon css
.connect-bluemoon {
  border-radius: 3px;
  background-image: linear-gradient(97deg, #3b1c5a, #374db1 163%);
  padding: 25px 20px;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
  h6 {
    font-size: 15px;
    font-weight: bold !important;
    font-family: "Be Vietnam", sans-serif !important;
    color: #ffffff;
    margin-bottom: 0px;
  }
  p {
    font-size: 13px;
    font-weight: normal;
    color: rgba(255, 255, 255, 0.7);
    max-width: 559px;
    line-height: 21px;
  }
  button {
    border-radius: 3px;
    background-color: rgba(255, 255, 255, 0.1);
    width: 120px;
    height: 35px;
    border: 0px;
    color: #fff;
    font-size: 11px;
    font-weight: 800;
    text-transform: uppercase;
    cursor: pointer;
  }
  a {
    color: #fff;
  }
  .benefits-txt-wrap {
    display: inline;
    margin-left: 5px;
    span {
      padding: 0px 4px;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
.benefits-modal-docu {
  .modal-dialog {
    max-width: 99%;
    margin: 0;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 90vh;
    z-index: 1;
    display: flex;
  }
}
.benefits-modal {
  position: absolute;
  //    left: 50%;
  top: 50%;
  .modal-content {
    border-radius: 3px;
    background-color: #f8f8f8;
    box-shadow: unset;
    .modal-header {
      background-color: unset;
      border: 0px;
      display: flex;
      padding: 10px 20px;
      button {
        padding: 0px;
        margin: 0px;
        margin-left: auto;
        width: auto;
        height: auto;
        span {
          color: #171a1d;
          font-size: 24px;
          font-weight: normal;
          cursor: pointer;
        }
      }
    }
    .modal-body {
      padding: 10px 50px;
      img {
        margin: 0px auto;
        display: flex;
      }
      h5 {
        font-size: 25px !important;
        font-weight: bold !important;
        color: #3b1c5a;
        text-align: center;
        margin-top: 25px;
        margin-bottom: 5px;
        font-family: "Be Vietnam", sans-serif !important;
      }
      p {
        font-size: 13px;
        font-weight: normal;
        max-width: 400px;
        margin: 0px auto;
        line-height: 25px;
        padding-bottom: 20px;
        border-bottom: 1px solid rgba(94, 98, 103, 0.1);
        margin-bottom: 20px;
        text-align: center;
      }
      .content-wrap {
        ul {
          padding: 0px;
          list-style: none;
          counter-reset: num-counter;
        }
        li {
          display: flex;
          &:before {
            counter-increment: num-counter;
            content: counter(num-counter);
            width: 20px;
            height: 20px;
            display: flex;
            background-image: linear-gradient(133deg, #3b1c5a, #374db1 158%);
            color: #fff;
            border-radius: 50%;
            align-items: center;
            justify-content: center;
            font-size: 10px;
            font-weight: 800;
            margin-top: 6px;
          }
          p {
            width: calc(100% - 30px);
            font-size: 14px;
            font-weight: normal;
            line-height: 26px;
            border-bottom: 0px;
            margin-bottom: 25px;
            padding-bottom: 0px;
            text-align: left;
            margin-left: 10px;
          }
        }
      }
    }
    .modal-footer {
      padding: 0px 50px 50px 50px;
      border: 0px;
      button {
        width: 100%;
        height: 45px;
        border-radius: 3px;
        background-color: #5e6267 !important;
        font-size: 13px;
        font-weight: 800;
        text-transform: uppercase;
        line-height: 20px;
      }
    }
  }
}
.completed-modal {
  // position: absolute;
  //    left: 50%;
  top: 5%;
  .modal-content {
    border-radius: 3px;
    background-color: #f8f8f8;
    box-shadow: unset;
    .modal-header {
      background-color: unset;
      border: 0px;
      display: flex;
      padding: 10px 20px;
      button {
        padding: 0px;
        margin: 0px;
        margin-left: auto;
        width: auto;
        height: auto;
        span {
          color: #171a1d;
          font-size: 24px;
          font-weight: normal;
          cursor: pointer;
        }
      }
    }
    .modal-body {
      padding: 10px 50px;
      img {
        margin: 0px auto;
        display: flex;
      }
      h5 {
        font-size: 25px !important;
        font-weight: bold !important;
        color: #3b1c5a;
        text-align: center;
        margin-top: 0px !important;
        margin-bottom: 5px;
        font-family: "Be Vietnam", sans-serif !important;
      }
      p {
        font-size: 13px;
        font-weight: normal;
        max-width: 400px;
        margin: 0px auto;
        line-height: 25px;
        padding-bottom: 20px;
        border-bottom: 1px solid rgba(94, 98, 103, 0.1);
        margin-bottom: 20px;
        text-align: center;
      }
      .content-wrap {
        ul {
          padding: 0px;
          list-style: none;
          counter-reset: num-counter;
        }
        li {
          display: flex;
          &:before {
            counter-increment: num-counter;
            content: counter(num-counter);
            width: 20px;
            height: 20px;
            display: flex;
            background-image: linear-gradient(133deg, #3b1c5a, #374db1 158%);
            color: #fff;
            border-radius: 50%;
            align-items: center;
            justify-content: center;
            font-size: 10px;
            font-weight: 800;
            margin-top: 6px;
          }
          p {
            width: calc(100% - 30px);
            font-size: 14px;
            font-weight: normal;
            line-height: 26px;
            border-bottom: 0px;
            margin-bottom: 25px;
            padding-bottom: 0px;
            text-align: left;
            margin-left: 10px;
          }
        }
      }
    }
    .modal-footer {
      // padding: 0px 50px 50px 50px;
      border: 0px;
      button {
        width: 100%;
        height: 45px;
        border-radius: 3px;
        background-color: #5e6267 !important;
        font-size: 13px;
        font-weight: 800;
        text-transform: uppercase;
        line-height: 20px;
      }
    }
  }
}

.toggle-button-cover {
  display: table-cell;
  position: relative;
  width: 80px;
  box-sizing: border-box;
  .button-cover,
  .knobs,
  .layer {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  .button {
    position: relative;
    top: 50%;
    width: 19px;
    height: 12px;
    margin: 0px auto;
    overflow: hidden;
    border: 1px solid #5e6267;
    border-radius: 30px;
    .knobs:before {
      content: "";
      position: absolute;
      top: 3px;
      left: 3px;
      width: 5px;
      height: 5px;
      color: #fff;
      font-size: 10px;
      font-weight: bold;
      text-align: center;
      line-height: 1;
      background-color: #5e6267;
      border-radius: 50%;
      transition: 0.3s cubic-bezier(0.18, 0.89, 0.35, 1.15) all;
    }
    .checkbox:checked + .knobs:before {
      content: "";
      left: 9px;
      background-color: #ffffff;
    }
    .checkbox:checked ~ .layer {
      background-image: linear-gradient(97deg, #3b1c5a, #374db1 163%);
    }
    .knobs,
    .knobs:before,
    .layer {
      transition: 0.3s ease all;
    }
  }
  .checkbox {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    opacity: 0;
    cursor: pointer;
    z-index: 3;
    top: -7px;
  }

  .knobs {
    z-index: 2;
  }

  .layer {
    width: 100%;
    transition: 0.3s ease all;
    z-index: 1;
  }
}

.enable-bluemoon {
  background-color: rgba(0, 0, 0, 0.05);
  margin-top: 20px;
  border-radius: 3px;
  padding: 20px;
  .top-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  .left-data {
    text-align: left;
    h6 {
      font-size: 14px;
      font-weight: 800 !important;
      color: #171a1d;
      margin-bottom: 0px;
      font-family: "Be Vietnam", sans-serif !important;
    }
    p {
      font-size: 12px;
      color: #5e6267;
      font-weight: normal;
    }
  }
  .selection.dropdown {
    margin-left: 0px;
    box-shadow: 0 11px 14px -10px #e5eced;
  }
  p {
    position: absolute;
    top: 36px;
    left: 20px;
  }
}

.enable-modal {
  .modal-dialog {
    max-width: 890px;
    .modal-content {
      box-shadow: unset;
      border-radius: 3px;
      // border: 1px dashed rgba(0, 0, 0, 0.3);
    }
    .modal-body {
      // border: 2px dashed rgba(0, 0, 0, 0.3);
      border-radius: 3px;
      display: flex;
      align-items: center;
      padding: 40px 25px;
      .left-data {
        text-align: left;
        display: flex;
        align-items: flex-start;
        width: calc(100% - 200px);
        .content-data {
          margin-left: 10px;
          h5 {
            font-size: 16px;
            font-weight: 800 !important;
            margin-bottom: 10px;
            font-family: "Be Vietnam", sans-serif !important;
          }
          p {
            font-size: 13px;
            font-weight: normal;
            max-width: 500px;
            line-height: 22px;
          }
        }
      }
      button {
        width: 200px;
        height: 45px;
        margin-left: auto;
        border-radius: 3px;
        background-color: #5e6267;
        border: 0px;
        color: #fff;
        cursor: pointer;
        font-size: 13px;
        font-weight: 800;
        text-transform: uppercase;
      }
    }
  }
}

.bluemoon-settings-wrap {
  h5 {
    font-size: 20px !important;
    margin-bottom: 50px;
    font-weight: 800 !important;
    font-family: "Be Vietnam", sans-serif !important;
  }
  .settings-list {
    ul {
      padding: 0px;
      list-style: none;
      li {
        height: 80px;
        margin-bottom: 20px;
        padding: 20px 20px 21px 30px;
        box-shadow: 0 11px 14px -10px #e5eced;
        background-color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: space-between;
        h6 {
          font-size: 14px;
          font-weight: 600 !important;
          color: #171a1d;
          margin-bottom: 0px;
          font-family: "Be Vietnam", sans-serif !important;
        }
        p {
          font-size: 13px;
          font-weight: normal;
          color: #5e6267;
          a {
            font-weight: bold;
            text-decoration: underline;
            color: #3b1c5a;
            font-family: "Be Vietnam", sans-serif !important;
          }
        }
        button {
          border-radius: 3px;
          background-color: rgba(59, 28, 90, 0.1);
          width: 120px;
          height: 35px;
          border: 0px;
          font-size: 11px;
          font-weight: 800;
          margin-right: 20px;
          cursor: pointer;
          color: #3b1c5a;
          text-transform: uppercase;
          font-family: "Be Vietnam", sans-serif !important;
        }
        img {
          cursor: pointer;
        }
      }
    }
  }
}

// credentials modal
.credentials-modal {
  .modal-content {
    .modal-body {
      padding: 0px 50px 0px 50px;
      h5 {
        font-size: 22px !important;
        font-weight: 800 !important;
        color: #171a1d;
        margin-top: 0px;
        text-align: left;
        margin-bottom: 30px;
      }
    }
    .modal-footer {
      button {
        background-image: linear-gradient(
          96deg,
          #3b1c5a,
          #374db1 163%
        ) !important;
        margin: 0px;
      }
    }
  }
}

// Microsites Modal
.microsite-height {
  height: 510px;
}
div.ui.half-image-section.divided.grid.microsite {
  margin: 0 !important;
}
.microsites-modal {
  &-col-spacing {
    margin-left: 40px;
  }
  &-close {
    background-color: rgba(255, 255, 255, 0) !important;
    background-image: linear-gradient(276deg, #7127c4, #4c158a) !important;
    color: white !important;
    &:hover {
      color: black !important;
    }
  }
  &-not-selected {
    position: absolute;
    right: 20px;
    bottom: 126px;
  }
  &-templates {
    margin-top: 20px !important;
    margin-left: 54px;
  }
  &-trigger-button {
    position: absolute;
    bottom: 114px;
    right: 20px;
  }
  &-set-template {
    position: absolute;
    bottom: 14px;
    background-color: green !important;
    color: white !important;
    &:hover {
      color: black !important;
    }
  }
}

.iframe-container {
  height: 88vh;
}

.business-hours {
  &-toggle {
    &:hover {
      cursor: pointer;
    }
  }
}

.loader-text {
  display: block;
  position: absolute;
  top: 84px;
}

.evocalizeModal {
  width: 90% !important;
  height: 95%;
  .actions {
  }
  .content {
    height: 93% !important;
    iframe {
      height: 85vh;
      overflow: auto;
    }
  }
}

#purple {
  color: white !important;
  background-image: linear-gradient(103deg, #3b1c5a, #374db1 162%);
}
