@import "../../styles/quillpro/quillpro-variables";
@import "../../styles/_variables";
.x-hellosign-embedded__iframe {
  min-height: 400px;
  background-color: #f7f8f9;
  height: 84% !important;
  border: none;
  min-width: 80% !important;
  margin: 82px 0px 0px 200px !important;
}
.buttonView {
  background:#f6f7f8 !important;
  .eye {
    color:#555555 !important;
  }
}
.buttonView:hover {
  background:#4C158A !important;
  .eye {
    color:white !important;
  }
}
.x-hellosign-embedded__modal-close {
  position: absolute;
  top: 42px !important;
  right: 5px !important;
  height: 40px;
  width: 40px;
}
.iframe-wrapper {
  min-height: 100px !important;
}
.review-background {
  margin-left: 15px !important;
}
iframe {
  // position: absolute;
  border: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-height: 300px;
}

.applications-container {
  .card.on {
    border-top: 1px solid $qp-color-primary !important;
    border-right: 1px solid $qp-color-primary !important;
    border-left: 1px solid $qp-color-primary !important;
    transform: translateY(10px);
    border-bottom-right-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
  }

  .application-card-grid {
    display: grid;
    grid-template-columns: fit-content(5.2rem) auto auto fit-content(5.2rem);
    grid-template-areas: "img adr ale but";

    .img {
      grid-area: img;

      .property-img {
        width: 5.2rem;
        border-bottom-left-radius: 0.4673rem !important;
        border-top-left-radius: 0.4673rem !important;
      }
    }
    .but {
      grid-area: but;
      .notification-number {
        width: 20px;
        height: 20px;
        display: inline-block;
        border-radius: 50%;
        background: red;
        text-align: center;
        line-height: 20px;
        font-size: 10px;
        color: #ffffff;
        box-shadow: -16px -12px 46px 0px rgba(0, 0, 0, 0.4);
        position: absolute;
        // transform: translateX(60px);
        right: -5px;
        top: -5px;
      }
    }
    .ale {
      grid-area: ale;
      align-self: end;
      justify-self: end;
      padding-right: 1rem;
      font-size: 1.5rem;
      transform: translateY(-10px);
      .notification-number {
        width: 20px;
        height: 20px;
        display: inline-block;
        border-radius: 50%;
        background: red;
        text-align: center;
        line-height: 20px;
        font-size: 10px;
        color: #ffffff;
        position: relative;
        top: -15px;
      }
    }
    .adr {
      grid-area: adr;
      align-self: center;
    }
  }

  table.table-hover {
    overflow: hidden;
    tbody {
      tr:hover.tenant-row {
        color: white;
      }
      td {
        text-transform: capitalize;
      }
    }
  }

  .table-applications {
    border-collapse: separate;
    border-spacing: 0;
    border-bottom: 1px solid $qp-color-primary;
    border-right: 1px solid $qp-color-primary;
    border-left: 1px solid $qp-color-primary;
    border-bottom-left-radius: 0.4673rem !important;
    border-bottom-right-radius: 0.4673rem !important;
    background: white;
    thead {
      background: $qp-color-secondary !important;
    }
  }

  .adr {
    font-size: 1.5rem;
    display: inline-block;

    span {
      vertical-align: bottom;
    }

    .alert {
      margin: 0;
      margin-top: 1rem;
      vertical-align: text-bottom;
    }
  }

  @media screen and (max-width: 767px) {
    .application-card-grid {
      .adr {
        display: grid;
        grid-template: auto / 1fr;

        span {
          font-size: 1.2rem;
        }
      }
      .ale {
        font-size: 1.2rem;
      }
    }
  }

  @media screen and (max-width: 992px) {
    .application-card-grid {
      display: grid;
      grid-template-areas:
        "img adr adr ale"
        "but but but but";
      .img {
        grid-area: img;
      }
      .but {
        grid-area: but;
        width: 100%;

        button {
          transform: translate(3px, 6px);
          width: 100%;
        }
      }
      .ale {
        grid-area: ale;
        justify-self: end;
      }
      .adr {
        grid-area: adr;
        font-size: 1.2rem;
      }
    }
  }
}

.form-steps-font {
  font-weight: bold;
  color: black;
}

.text-area {
  height: "150px";
  max-height: "500px";
  border-radius: "5px";
  border: "1px solid #4C158A";
}

// application UI CSS

.application-step-wrapper {
  margin: 15px 0 10px 0;
  .ui {
    &.steps {
      border: 0;
      .step {
        background: #f3f4f5;
        padding-top: 12px;
        padding-bottom: 12px;
        .description {
          font-weight: bold;
        }
        &::after {
          background: #f3f4f5;
        }
        &.active {
          background: #4c158a;
          border-right-color: #4c158a;
          &::after {
            background: #4c158a;
            border-color: #4c158a;
          }
          .description {
            color: #fff;
          }
        }
      }
    }
  }
}

.page-head {
  font-family: "Lato", "Helvetica Neue", Arial, Helvetica, sans-serif;
  font-size: 1.71428571rem;
  color: rgba(0, 0, 0, 0.87);
}

.add-new-app-head {
  margin: 0 0 20px 0;
  p {
    font-weight: 700;
    font-size: 14px;
  }
}

.move-date {
  max-width: calc(100% / 3);
  width: calc(100% / 3);
  padding-left: 0.5em;
  padding-right: 0.5em;
  .form-control {
    box-sizing: border-box;
    height: 38px;
  }
  .move-date-label {
    color: rgba(0, 0, 0, 0.5);
    font-size: 14px;
    font-family: "Avenir-regular", sans-serif;
    font-weight: 300;
    margin-bottom: 7px;
  }
}

th.rdtSwitch:hover, th.rdtPrev:hover, th.rdtNext:hover {
  background-color: #4c158a !important;
}

.modal {
  .move-date {
    max-width: 100%;
    width: 100%;
  }
}

.unit-info-form,
.primary-applicant-form,
.co-applicant-form {
  margin-top: 25px;
  &.ui.form {
    .field {
      > label {
        color: rgba(0, 0, 0, 0.5);
        font-size: 14px;
        margin-bottom: 7px;
      }
    }
    [class*="equal width"] {
      &.fields.unit-info-form-first-row {
        > .field {
          width: calc(100% / 3);
        }
      }
    }
    .fields {
      &:not(.grouped) {
        &:not(.inline) {
          .field {
            &:not(:only-child) {
              .ui.checkbox {
                margin-top: 0;
              }
            }
          }
        }
      }
      .radio-col {
        .field {
          display: inline-block;
        }
      }
    }
    textarea {
      resize: none;
      height: 60px;
    }
  }
}

.radio-col {
  width: calc(100% / 3);
  padding-left: 0.5em;
  padding-right: 0.5em;
  .radio-col-label {
    color: rgba(0, 0, 0, 0.5);
    font-size: 14px;
    margin-bottom: 7px;
    font-weight: bold;
  }
}

.pets-col {
  width: calc(100% / 3);
}

.radio-wrapper {
  height: 38px;
  display: flex;
  align-items: center;
  label {
    margin-bottom: 0;
  }
}

.margin-top-2 {
  .ui {
    &.fluid {
      &.input {
        margin-top: -2px;
      }
    }
  }
}

.emergency-contact-last-row {
  .pets-col {
    width: 25%;
  }
}

.primary-applicant-header {
  color: #343c49;
  font-size: 18px;
  font-weight: bold;
  margin: 25px 0 20px 0;
}

.primary-applicant-subheader {
  color: #343c49;
  font-size: 16px;
  font-weight: bold;
  margin: 0px 0 18px 0;
}

.ui {
  &.segment {
    &.primary-applicant-segment {
      box-shadow: none;
      padding: 18px 18px 12px 18px;
      &.merge-section {
        &:not(:last-child) {
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
          margin-bottom: 0;
        }
        & + .merge-section {
          border-top: 0;
          border-top-left-radius: 0;
          margin-top: 0;
        }
      }
    }
    &.primary-applicant-main-segment {
      box-shadow: none;
      padding: 0;
      margin: 0;
      border: 0;
    }
  }
}

.segment-header-box {
  background: rgba(127, 135, 147, 0.05);
  margin-left: -19px;
  margin-right: -19px;
  color: #fff;
  padding: 8px 18px;
  margin-bottom: 18px;
  height: 52px;
  border-bottom: 1px solid rgba(34, 36, 38, 0.15);
  .primary-applicant-subheader {
    margin: 0;
  }
}

// !: Correct rule for it's intended use
// .wid-110 {
//   width: 10px;
// }

.mt-18 {
  margin-top: 18px;
}
.mt-25 {
  margin-top: 25px;
}

.ui {
  &.positive {
    &.button {
      &.add-income-button {
        background: none;
        padding: 0;
        &:hover,
        &:focus,
        &:active {
          background: none;
        }
        > .icon:not(.button) {
          opacity: 1;
          color: #4c158a;
        }
      }
      &.remove-income-button {
        background: none;
        padding: 0;
        position: absolute;
        top: 14px;
        right: 0;
        &:hover,
        &:focus,
        &:active {
          background: none;
        }
        > .icon:not(.button) {
          opacity: 1;
          color: rgb(244, 67, 54);
        }
      }
    }
  }
}

.income-input-row {
  position: relative;
  .equal {
    &.width {
      &.fields {
        width: calc(100% - 35px);
      }
    }
  }
}

.background-info-row {
  margin-bottom: 15px;
}

.ui {
  &.segment {
    &:empty {
      padding: 0;
      border: 0;
    }
  }
}

.ui {
  &.styled {
    &.accordion {
      &.custom-accordian {
        box-shadow: none;
        border: 1px solid rgba(34, 36, 38, 0.15);
        .title {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding-top: 12px;
          padding-bottom: 12px;
          color: #343c49;
          font-size: 15px;
          background: rgba(34, 36, 38, 0.05);
          &:hover {
            background: rgba(34, 36, 38, 0.05);
          }
          &.active {
            &:hover {
              background: transparent;
            }
          }
        }
      }
    }
  }
}

.occupant-table {
  border-collapse: collapse !important;
  box-shadow: 0 0 0 1px rgba(34, 36, 38, 0.1);
  border: 0;
  > thead {
    > tr {
      > th {
        padding-left: 15px;
        padding-right: 15px;
        padding-top: 0.78571429em;
        padding-bottom: 0.78571429em;
        background: #f9fafb;
        color: rgba(0, 0, 0, 0.87);
        text-align: left;
        border-top: 0;
      }
    }
  }
  > tbody {
    > tr {
      > td {
        padding-left: 15px;
        padding-right: 15px;
        padding-top: 0.78571429em;
        padding-bottom: 0.78571429em;
        vertical-align: middle;
      }
    }
  }
}

.occupants-date {
  input[type="text"] {
    &.form-control {
      margin: 0;
      border: 1px solid rgba(34, 36, 38, 0.15);
      box-shadow: none;
      height: 38px;
      box-sizing: border-box;
      width: 100%;
    }
  }
}

.min-wid-85 {
  min-width: 85px;
}

.form-data-wrapper {
  display: flex;
  flex-wrap: wrap;
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 0.28571429rem;
  margin-top: 25px;
  padding: 0;
  .form-data-col {
    width: calc(100% / 3);
    padding: 8px 15px;
    border-bottom: 1px solid rgba(34, 36, 38, 0.15);
    &:nth-child(3n + 2) {
      border-left: 1px solid rgba(34, 36, 38, 0.15);
      border-right: 1px solid rgba(34, 36, 38, 0.15);
    }
  }
  .form-data-label {
    font-size: 15px;
    margin-bottom: 2px;
    color: rgba(0, 0, 0, 0.5);
    font-weight: bold;
  }
  .form-data-text {
    font-size: 14px;
    color: #343c49;
  }
}

.applicant-info-box {
  display: flex;
  flex-wrap: wrap;
  border-radius: 0;
  margin-top: 0;
  padding: 0 0 8px 0;
  .applicant-info-col {
    width: calc(100% / 3);
    padding: 8px 15px;
  }
  .applicant-info-label {
    font-size: 15px;
    margin-bottom: 2px;
    color: rgba(0, 0, 0, 0.5);
    font-weight: bold;
  }
  .applicant-info-text {
    font-size: 14px;
    color: #343c49;
  }
}

.table-container {
  &.application-table {
    tr {
      th {
        background: #343c49;
        &::before {
          top: 50%;
          margin-top: -2.5px;
        }
        &:nth-child(3),
        &:nth-child(7),
        &:nth-child(8) {
          cursor: pointer;
          height: 57px;
          padding-right: 18px;
          .header-filter-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: row-reverse;
          }
          &::before {
            right: 10px;
          }
        }
      }
    }
  }
}

.first-lastname-text {
  text-transform: capitalize;
}

.co-applicant-text {
  display: inline-block;
  margin-right: 12px;
}

.decision-summary-box {
  display: flex;
  flex-wrap: wrap;
  padding: 8px 0;
  border: 1px solid rgba(34, 36, 38, 0.15);
  background: rgba(34, 36, 38, 0.05);
  border-radius: 0.28571429rem;
  .decision-summary-col {
    width: calc(100% / 3);
    padding: 8px 15px;
  }
  .decision-summary-label {
    font-size: 15px;
    margin-bottom: 2px;
    color: rgba(0, 0, 0, 0.5);
    font-weight: bold;
  }
  .decision-summary-text {
    font-size: 14px;
    color: #343c49;
  }
}

.application-status {
  display: inline-block;
  background: #4c158a;
  color: #fff;
  border-radius: 20px;
  padding: 8px 18px;
  font-weight: bold;
}

.button-link {
  background: #4c158a;
  color: #fff;
  font-weight: bold;
  padding: 9px 20px;
  border-radius: 0.28571429rem;
}

.no-results-text {
  font-weight: bold;
  font-size: 16px;
}

.background-check-text {
  display: block;
  color: #72848c;
}

th {
  &.required-field {
    &::after {
      content: "*";
      color: #db2828;
      display: inline-block;
      vertical-align: top;
      margin: -0.2em 0 0 0.2em;
    }
  }
}

.review-address-heading {
  padding: 15px 15px 0 15px;
  font-size: 15px;
  margin-bottom: 2px;
  color: rgba(0, 0, 0, 0.7);
  font-weight: bold;
  border-top: 1px solid rgba(34, 36, 38, 0.15);
}

.review-address-paragraph {
  padding: 0 15px;
  font-size: 14px;
  color: #343c49;
}

.co-applicant-primary-address {
  .pets-col {
    width: 100%;
  }
}

.decision-summary-sub-head {
  display: block;
  color: rgba(0, 0, 0, 0.7);
  font-weight: bold;
  margin: 12px 0;
}

.no-results-text-application {
  max-width: 500px;
  margin: auto;
}

.not-found-icon {
  width: 109px;
  height: 136px;
  display: inline-block;
  // background: url("/assets/img/no-found.png");
  background-repeat: no-repeat;
  background-size: 100%;
  opacity: 0.5;
}

.app-content .ui.form .disabled.fields .field,
.app-content .ui.form .disabled.field,
.app-content .ui.form .field :disabled {
  opacity: 1;
  cursor: not-allowed !important;
  pointer-events: auto;
}
.app-content .ui.form .field.disabled > label,
.app-content .ui.form .fields.disabled > label,
.app-content .ui.disabled.input,
.app-content .ui.input:not(.disabled) input[disabled] {
  opacity: 1;
}

.application-checkbox {
  max-width: 180px;
  .ui.checkbox {
    margin-top: 36px !important;
  }
}
