.has-float-label {
  display: block;
  position: relative;
  margin: 0;
  border-radius: 3px;
  box-shadow: 0 11px 14px -10px #e5eced;
  background-color: #fff;
  height: 75px;
  .form-control {
    height: 68px;
    border: 0;
    background: transparent !important;
    z-index: 2;
    position: relative;
    text-align: left;
    box-shadow: none;
    padding: 20px 20px 0;
    font-size: 14px;
    font-weight: 500;
    color: #171a1d;
    box-sizing: border-box;
    &:focus {
      border: 0 !important;
      box-shadow: none !important;
    }
  }
  &.with-doller {
    .doller-sign {
      position: absolute;
      top: 34px;
      font-weight: 500px;
      line-height: normal;
      color: #171a1d !important;
    }
    .form-control {
      padding-left: 33px;
    }
  }
  &.grey-input {
    background: rgba(0, 0, 0, 0.05);
  }
}
.has-float-label label,
.has-float-label > span {
  position: absolute;
  cursor: text;
  font-size: 13px;
  font-weight: 500;
  color: #5e6267 !important;
  opacity: 1;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  top: 15px;
  left: 20px;
  z-index: 9;
  padding: 0 1px;
  font-family: "Be Vietnam", sans-serif !important;
}
.has-float-label label::after,
.has-float-label > span::after {
  content: " ";
  display: block;
  position: absolute;
  background: transparent;
  height: 2px;
  top: 50%;
  left: -0.2em;
  right: -0.2em;
  z-index: -1;
}
.has-float-label .form-control::-webkit-input-placeholder {
  opacity: 0;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}
.has-float-label .form-control::-moz-placeholder {
  opacity: 0;
  transition: all 0.2s;
}
.has-float-label .form-control:-ms-input-placeholder {
  opacity: 0;
  transition: all 0.2s;
}
.has-float-label .form-control::placeholder {
  opacity: 0;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}

.has-float-label
  .form-control:placeholder-shown:not(:focus)::-webkit-input-placeholder {
  opacity: 0;
}
.has-float-label .form-control:placeholder-shown:not(:focus)::-moz-placeholder {
  opacity: 0;
}
.has-float-label
  .form-control:placeholder-shown:not(:focus):-ms-input-placeholder {
  opacity: 0;
}
.has-float-label .form-control:placeholder-shown:not(:focus)::placeholder {
  opacity: 0;
}
.has-float-label .form-control:placeholder-shown:not(:focus) + * {
  font-size: 15px;
  top: 25px;
  color: #959595;
}

.select-float-label {
  .ui.fluid.dropdown {
    height: 75px;
    border: 0;
    padding-top: 36px;
    padding-left: 20px;
    width: 100% !important;
    .text {
      font-size: 14px;
      font-weight: 500;
      color: #171a1d;
      line-height: normal;
    }
    background: transparent !important;
    .dropdown.icon {
      top: 27px !important;
    }
    .menu {
      box-shadow: 0 11px 14px -10px #e5eced;
      background-color: #ffffff;
      border: 0;
    }
  }
  .blank-select + label {
    font-size: 15px;
    top: 25px;
  }
}

.textarea-label {
  height: auto;
  padding-top: 15px;
  
  .form-control:placeholder-shown:not(:focus) + * {
    top: 17px;
  }
  .form-control {
    padding-top: 23px;
  }
  label {
    width: calc(100% - 40px);
    text-align: left;
    background: #fff;
    z-index: 2;
    button {
      display: flex !important;
      margin-left: auto !important;
    }
  }
}

.error-msg {
  color: red;
  width: 100%;
  text-align: left;
  display: block;
}

// form input css
.form-data-wrap {
  .form-group {
    margin-bottom: 20px;
    position: relative;
    label {
      color: #898da0;
      margin: 0;
      position: absolute;
      top: 15px;
      left: 20px;
      font-size: 13px;
    }
    .form-control {
      background: #fff;
      border-color: transparent;
      border-radius: 3px;
      box-shadow: 0 11px 14px -10px #e5eced !important;
      width: 100%;
      height: 75px;
      color: #171a1d;
      padding: 34px 20px 20px;
      font-size: 14px;
      font-weight: 500;
      line-height: normal;
      box-sizing: border-box;
      width: 100% !important;
      border: 0 !important;
    }
  }
}
