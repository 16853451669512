.upload-btn-wrapper {
    position: relative;
    overflow: hidden;
    display: inline-block;
  }
  
  .btnUp {
    border: 2px solid gray;
    color: gray;
    background-color: white;
    padding: 8px 20px;
    border-radius: 8px;
    font-size: 20px;
    font-weight: bold;
    span {
        color:#3a2468;
    }
  }
  
  .upload-btn-wrapper input[type=file] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
  }

  select {
    width: 200px;
    min-width: 200px;
    margin: 10px;
    height:42px;
    border: 2px solid gray;
    color: #3a2468;
    border-radius: 8px;
    text-transform:capitalize !important;
    option{
        text-transform:capitalize
    }
    option:focus{
        text-transform:capitalize
    }
}
select:focus {
    width: 150px;
    
}

.successColor {
    background: #24c7af !important;
    transition: background 0.5s ease-in-out;
}
.fileName {
    color:black !important;
    font-weight: bold;
}
.exitIcon {
    // background:red;
    cursor: pointer !important;
    margin-left: auto !important;
}
.backIcon {
    // background:red;
    cursor: pointer !important;
    margin-right: auto !important;
    padding-top:20px;
}
.title-issue {
    text-transform:capitalize
}