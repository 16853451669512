.topLevel {
  display: grid;
  grid-template-areas: "left right";
  width: 100%;
  grid-template-columns: 50% 50%;
  .right_info {
    grid-area: right;
    .frm_seperator {
      h1 {
        padding-left: 20px;
      }
      ul {
        display: grid;
        list-style-type: none;
        li {
          padding: 5px;
        }
      }
      grid-area: left;
      div {
        display: inline;
      }
      label {
        padding-left: 20px;
        font-weight: bold;
        display: inline;
      }
      label::after {
        content: ": ";
      }
      label::before {
        content: "- ";
      }
    }
  }
  .left_info {
    h1 {
      padding-left: 20px;
    }
    ul {
      display: grid;
      list-style-type: circle;
      li {
        padding: 5px;
      }
    }
    grid-area: left;
    div {
      display: inline;
    }
    label {
      padding-left: 20px;
      font-weight: bold;
      display: inline;
    }
    label::after {
      content: ": ";
    }
    label::before {
      content: "- ";
    }
  }
  .applicant_details ul {
    margin: 0 auto;
    padding: 0;
  }
  label {
    text-align: center;
  }
}

.ta_details {
  page-break-inside: avoid;
}
li > .info_details {
  display: inline-block !important;
}

li > .info_details:first-letter,
li > .fl > .info_details:first-letter {
  text-transform: uppercase;
}
.report-html.info-forms-wrap {
  border-bottom: none;
}
.report-html textarea {
  width: 333px;
  height: 200px;
}
.agt-bs3 [class*=\"col-\"] {
  width: 100%;
  list-style: none;
  padding: 5px 0px;
  float: left;
  color: #666666;
  font-size: 13px;
}

.ta_details legend {
  font-size: 18px;
  font-weight: normal;
  padding-bottom: 5px;
  text-transform: uppercase;
  display: block;
  width: 100%;
}

.application-details .agt-bs3 span.info_details.checkbox-confirm {
  display: inline;
  text-transform: capitalize;
}

.application-details .agt-bs3 span.info_details.checkbox-confirm::after {
  content: \- \;;
}
.application-details .agt-bs3 .row {
  padding: 0;
}
.application-details .ta_details legend {
  font-size: 12px;
}
.application-details .ta_details fieldset {
  padding: 0;
}
.application-details .agt-bs3 .control-group label {
  float: left;
  width: 130px;
  padding-top: 3px;
  line-height: 20px;
  font-size: 13px;
}
fieldset,
legend {
  border: none;
}
