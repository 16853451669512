.ui.progress {
  // position: absolute !important;
  // z-index: 3;
  // top: 12px;
  // width: 150px;
  // left: 16px;
  // background: rgba(#171a1d, 0.65) !important;
  // height: 20px !important;
  // border-radius: 10px !important;
  .bar {
    background: #11ae7d !important;
    border-radius: 10px !important;
    height: 20px !important;
    .progress {
      background: transparent;
      color: #fff !important;
      right: auto !important;
      left: 10px !important;
      top: 4px !important;
      margin: 0 !important;
      font-size: 11px !important;
      font-weight: 800 !important;
    }
  }
}
