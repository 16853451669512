@import "../../styles/quillpro/quillpro-variables";
@import "../../styles/_variables";

.leases-container {
  .application-card-grid {
    display: grid;
    grid-template-columns: fit-content(5.2rem) auto auto fit-content(5.2rem);
    grid-template-areas: "img adr ale but";

    .img {
      grid-area: img;

      .property-img {
        width: 5.2rem;
        border-bottom-left-radius: 0.4673rem !important;
        border-top-left-radius: 0.4673rem !important;
      }
    }
    .but {
      grid-area: but;
    }
    .ale {
      grid-area: ale;
      align-self: end;
      justify-self: end;
      padding-right: 1rem;
      font-size: 1.5rem;
    }
    .adr {
      grid-area: adr;
      align-self: center;
    }
  }

  table.table-hover {
    tbody {
      tr:hover.tenant-row {
        color: white;
      }
    }
  }

  .table-applications {
    border-collapse: separate;
    border-spacing: 0;
    border: solid $qp-color-darkblue 2px;
    border-radius: 0.4673rem !important;
  }

  .adr {
    font-size: 1.5rem;
    display: inline-block;

    span {
      vertical-align: bottom;
    }

    .alert {
      margin: 0;
      margin-top: 1rem;
      vertical-align: text-bottom;
    }
  }

  @media screen and (max-width: 767px) {
    .application-card-grid {
      .adr {
        display: grid;
        grid-template: auto / 1fr;

        span {
          font-size: 1.2rem;
        }
      }
      .ale {
        font-size: 1.2rem;
      }
    }
  }

  @media screen and (max-width: 992px) {
    .application-card-grid {
      display: grid;
      grid-template-areas:
        "img adr adr ale"
        "but but but but";
      .img {
        grid-area: img;
      }
      .but {
        grid-area: but;
        width: 100%;

        button {
          transform: translate(3px, 6px);
          width: 100%;
        }
      }
      .ale {
        grid-area: ale;
        justify-self: end;
      }
      .adr {
        grid-area: adr;
        font-size: 1.2rem;
      }
    }
  }
}

.lease-no-result {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 125px);
}

.lease-table-listing {
  th {
    &.table-header {
      &:nth-child(2) {
        .header-filter-wrapper {
          display: flex;
          align-items: center;
          flex-direction: row-reverse;
          justify-content: center;
        }
      }
    }
  }
}
