@import "../../SiteShared.scss";

.editable {
  color: #4c158a !important;
}

.comments-textarea-wrapper {
  position: relative;
  .ui {
    &.update-button {
      position: absolute;
      top: auto;
      padding-right:25px !important;
      bottom: 10px;
      right: 10px;
      background: transparent;
      height: auto;
      width: auto;
      margin: 0;
      padding: 0;
    }
    &.positive {
      &.update-button {
        background-color: transparent;
        &:hover,
        &:active,
        &:focus {
          background-color: transparent;
        }
      }
    }
    &.button {
      &:not(.icon) {
        > .right.icon {
          &:not(.button) {
            &:not(.dropdown) {
              display: block;
              margin: 0 auto 10px auto;
            }
          }
        }
      }
    }
  }
}
